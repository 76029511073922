<template>
  <div style="height: 90%">
    <v-card
      :loading="loadingComments"
      flat
      style="
        height: 85vh;
        border-radius: 10px;
        background: var(--v-toolbar-lighten1) !important;
      " class="infoCards"
    >
      <v-card-text class="mx-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" class="my-0 py-0 mx-0 px-0">
            <v-list subheader class="px-3">
              <v-subheader
                class="mt-0 pt-0 primaryText--text"
                style="font-size: 16px"
              >
                <v-icon left color="grey" class="mr-2">chat</v-icon>
                Comments
              </v-subheader>
              <v-list
                dense
                style="height: 70vh; overflow-y: auto"
                v-chat-scroll
                :key="commentKey"
              >
                <v-list-item
                  v-for="(comment, index) in commentList"
                  :key="comment.id"
                  class="py-0 my-1"
                  :id="'comment_' + index"
                >
                  <v-list-item-avatar
                    v-if="comment.userId != $store.state.user.id"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          v-on="on"
                          style="cursor: pointer"
                          :color="comment.user.avatar?'white':'secondary'"
                          size="48"
                          class="mt-0 pt-0"
                        >
                        <h3
                            v-if="!comment.user.avatar"
                            >{{ comment.user.firstname.charAt(0) }}</h3
                          >
                          <v-img
                            v-else
                            :src="comment.user.avatar"
                            referrerpolicy="no-referrer"
                            contain
                          ></v-img> 
                        </v-avatar>
                      </template>
                      <span style="font-size: 12px"
                        >{{ comment.user.firstname }}
                        {{ comment.user.surname }}</span
                      >
                    </v-tooltip>
                  </v-list-item-avatar>
                  <v-list-item-content
                    :class="
                      comment.userId == $store.state.user.id
                        ? 'text-right'
                        : 'text-left'
                    "
                  >
                    <v-list-item-title class="text-wrap">
                      {{ comment.comment }}
                    </v-list-item-title>
                    <v-list-item-subtitle style="font-size: 12px; color: grey">
                      {{ comment.organisation.name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <i style="font-size: 12px; color: grey">{{
                        formatTime(comment.createdAt)
                      }}</i>
                      <v-chip
                        style="right: 0"
                        outlined
                        v-if="comment.internal"
                        x-small
                        color="blue"
                        class="white--text ml-5"
                        >Internal</v-chip
                      >
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar
                    v-if="comment.userId == $store.state.user.id"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          v-bind="attrs"
                          v-on="on"
                          style="cursor: pointer"
                          :color="comment.user.avatar?'white':'secondary'"
                          size="48"
                          class="mt-0 pt-0"
                        >
                          <h3
                            v-if="!comment.user.avatar"
                            >{{ comment.user.firstname.charAt(0) }}</h3
                          >
                          <v-img
                            v-else
                            :src="comment.user.avatar"
                            referrerpolicy="no-referrer"
                            contain
                          ></v-img>
                        </v-avatar>
                      </template>
                      <span style="font-size: 12px"
                        >{{ comment.user.firstname }}
                        {{ comment.user.surname }}</span
                      >
                    </v-tooltip>
                  </v-list-item-avatar>
                </v-list-item>
                <v-list-item v-if="commentList.length == 0 && !loadingComments">
                  <v-list-item-content class="text-center">
                    <span style="font-size: 12px; color: grey">
                      No comments.
                    </span>
                  </v-list-item-content></v-list-item
                >
              </v-list>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-text-field
          style="width: 100%"
          v-model="comment"
          clearable
          outlined
          hide-details
          placeholder="Message"
          type="text"
          v-on:keyup.enter="addComment"
        >
          <template v-slot:prepend>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  class="my-0 py-0"
                  @click="internalComment = !internalComment"
                  :color="internalComment ? 'blue' : 'primaryText'"
                  ><v-icon>vpn_lock</v-icon></v-btn
                >
              </template>
              <span style="font-size: 12px">Mark as Internal</span>
            </v-tooltip>
          </template>
          <template v-slot:append-outer>
            <v-btn icon @click="addComment"><v-icon>send</v-icon></v-btn>
          </template>
        </v-text-field>
      <!-- <v-card-actions style="background: var(--v-toolbar-lighten1) !important">
      
      </v-card-actions> -->
    </v-card>
  </div>
</template>
<script>
import dateFormat from "dateformat";
// import "floating-vue/dist/style.css";
export default {
  props: ["item", "open", "consigneeProfileComments", "loadingComments"],
  components: {
    // Mentionable,
  },
  data: () => ({
    // consigneeProfileComments: [],
    comment: null,
    commentKey: 0,
    commentMentions: [],
    filterComments: null,
    internalComment: true,
    unreadMessages: 0,
  }),
  watch: {
    // "item.id": {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       this.getComments();
    //       this.$socket.onmessage = (data) => {
    //   let message = JSON.parse(data.data);
    //   if(message)
    //     this.handleMessage(message)
    // };
    //     }
    //   },
    // },

    filterComments() {
      this.commentKey++;
    },
  },
  computed: {
    commentList() {
      let result = this.consigneeProfileComments;
      if (this.filterComments == "Internal") {
        result = result.filter((x) => x.internal);
      } else if (this.filterComments == "External") {
        result = result.filter((x) => !x.internal);
      }
      return result;
    },
  },
  unmounted() {
    delete this.$socket.onmessage;
  },
  methods: {
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen
          ? this.newMessagesCount
          : this.newMessagesCount + 1;
        this.onMessageWasSent({
          author: "support",
          type: "text",
          id: Math.random(),
          data: { text },
        });
      }
    },
    handleTyping(text) {
      this.showTypingIndicator =
        text.length > 0
          ? this.participants[this.participants.length - 1].id
          : "";
    },
    onMessageWasSent(message) {
      this.messageList = [
        ...this.messageList,
        Object.assign({}, message, { id: Math.random() }),
      ];
    },
    playNotification() {
      var audioFile = new Audio("https://cdn.loglive.io/sounds/pristine.mp3");
      audioFile.play();
    },
    // onOpen(key) {
    //   this.items = key === "@" ? users : issues;
    // },

    // onApply(item, key, replacedWith) {
    //   console.log(item, `has been replaced with ${replacedWith}`);
    // },
    async addComment() {
      if (this.comment) {
        this.commentMentions = this.commentMentions.filter((x) =>
          this.comment.includes(x.value)
        );
        let obj = {
          comment: this.comment,
          consigneeProfileId: this.item.id,
          internal: this.internalComment,
          user: {
            firstname: this.$store.state.user.firstname,
            surname: this.$store.state.user.surname,
            avatar: this.$store.state.user.avatar,
            emailAddress: this.$store.state.user.emailAddress,
          },
          userId: this.$store.state.user.id,
          organisation: this.$store.state.currentOrg,
          createdAt: new Date(),
          consigneeProfileCommentMentions: this.commentMentions,
        };
        this.$emit("newMessage", obj);
        if (this.item.id) {
          this.comment = null;
          await this.$API.createConsigneeProfileComment(obj).then((result) => {
            this.$emit("updateMessage", result);

            this.$socket.send(
              JSON.stringify({
                action: "sendmessage_room",
                room: "consigneeProfile_" + this.item.id,
                message: {
                  type: "message",
                  room: "consigneeProfile_" + this.item.id,
                  data: result,
                },
              })
            );
          });
        }
        this.commentKey++;
        this.comment = null;
        this.commentMentions = [];
      }
    },
    formatTime(time) {
      let result = null;
      if (time) {
        result = dateFormat(new Date(time), "yyyy-mm-dd HH:MM");
      }
      return result;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}</style>
