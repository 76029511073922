<template>
  <div>
    <div v-if="!loading && profile" style="height: 100vh; contain: content">
      <v-card
        style="
          background: rgba(0, 0, 0, 0.4) !important;
          box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
          backdrop-filter: blur(20px);
          -webkit-backdrop-filter: blur(20px);
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 0px;
          height: 100%;
          max-height: 100%;
          overflow-y: auto;
          width: 100%;
        "
      >
        <v-toolbar dense flat>
          <v-toolbar-title style="font-size: 16px">
            <b>
                <v-avatar size="32" :color="selectedCustomer.relatedOrganisation.logo ?'white':'secondary'">
                  <v-img v-if="selectedCustomer.relatedOrganisation.logo" :src="selectedCustomer.relatedOrganisation.logo"  contain/>
                  <h3 v-else>
                    {{ selectedCustomer.relatedOrganisation.name.charAt(0) }}
                  </h3>
                </v-avatar> {{
            selectedCustomer.relatedOrganisation.name
          }}</b>
            #{{ profile.systemReference }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="redPop" @click="$emit('close')">
            <v-icon small>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="my-0 py-0">
          <div class="contents">
            <div class="mainContentWrap">
              <v-tabs height="30px" v-model="currentTab" class="tabby" style="">
                <v-tab
                  v-for="(tab, index) in tabs"
                  :key="index"
                  :href="`#${tab.value}`"
                >
                  {{ tab.name }}
                </v-tab>
              </v-tabs>
              <div class="mainContent">
              <div v-show="currentTab == 'collaborators'">
                <v-row class="pa-2">
                    <v-col cols="12" md="6">
                      <GeneralInformation
                        :item="profile"
                        :selectedCustomer="selectedCustomer"
                        :related="related"
                        @refreshRelated="$emit('refreshRelated')"
                        @refresh="$emit('refresh')"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <RelationshipOverview
                        :profile="profile"
                        :contacts="contacts"
                        :functionalRoles="roles"
                        :selectedCustomer="selectedCustomer"
                        @addCustomer="$emit('addCustomer', $event)"
                        @refreshCustomer="$emit('refreshCustomer')"
                      />
                    </v-col>
                    <v-col cols="12" md="3">
                      <Chat
                        :open="true"
                        :item="profile"
                        :consigneeProfileComments="[]"
                        :loadingComments="false"
                      />
                    </v-col>
                  </v-row>
              </div>
              <div v-show="currentTab == 'settings'">
                <v-row class="pa-2">
                    <v-col cols="12" md="6">
                      <LocationSettings
                        :item="profile"
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <FreightDealTerm :profile="profile" :customers="customers" :contacts="contacts" />
                    </v-col>
                  </v-row>
              </div>
            </div>
            </div>
           
          </div>
          
        </v-card-text>
      </v-card>
    </div>
    <div
      v-else
      style="
        height: 100%;
        padding-top: 0;
        margin-top: 0;
        background: rgba(0, 0, 0, 0.4) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
      "
      class="text-center"
    >
      <valhalla-loading />
    </div>
  </div>
</template>
<script>
import Chat from "../ShipmentProfile/Chat.vue";
import FreightDealTerm from "../ShipmentProfile/FreightDealTerms.vue";
import GeneralInformation from "../ShipmentProfile/GeneralInformation.vue";
import LocationSettings from "../ShipmentProfile/LocationSettings.vue";
import RelationshipOverview from "../ShipmentProfile/RelationshipOverview.vue";
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    selectedCustomer: {
      type: Object,
      required: true,
    },
  },
  components: {
    Chat,
    FreightDealTerm,
    GeneralInformation,
    LocationSettings,
    RelationshipOverview,
  },
  data: () => ({
    availableRoles: [],
    contacts: [],
    currentTab: "collaborators",
    loading: false,
    profile: null,
    related: [],
    roles: [],
    tabs: [
      {
        name: "Collaborators",
        value: "collaborators",
      },
      {
        name: "Settings",
        value: "settings",
      },
      {
        name: "Booking Settings",
        value: "bookings",
        roleValue: 'BOOKING_COORDINATOR'
      },
      {
        name: "Export Documents",
        value: "exportDocument",
        roleValue: 'EXPORT_DOCUMENTATION'
      },
      {
        name: "Import Documents",
        value: "importDocument",
        roleValue: 'IMPORT_DOCUMENTATION'
      },
    ],
  }),
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.load(val);
        }
      },
    },
  },
  computed: {
    customers(){
        let organisations = this.contacts.map(x=>x.relatedOrganisation)
        let result = this.profile.consigneeProfileRelationships.map(x=>({
            ...x,
            organisation: organisations.find(y=>y.id==x.customerId),
            organisationId: x.customerId
        }))
        return result
    },
  },
  created() {
    this.initialLoad();
  },
  methods: {
    async getContacts(){
        let data = await this.$API.getRelation({});
        this.contacts = data.data
    },
    async getRelatedOrganisations() {
      this.related = await this.$API.getCustomerRelationships(
        this.selectedCustomer.relatedOrganisationId
      );
    },
    async initialLoad() {
        this.getContacts()
        this.getRelatedOrganisations()
    },
    async load(id) {
      this.loading = true;
      this.profile = await this.$API.getConsigneeProfile(id);
      this.profile.consigneeProfileRelationships = await this.$API.getConsigneeProfileRelationships(id);
      this.profile.consigneeProfileShares =  await this.$API.getConsigneeProfileShareSetting(id);
      this.availableRoles = await this.$API.getAvailableRoles();
      let roles = await this.$API.getRelationshipFunctions(this.selectedCustomer.id)
      this.roles = this.availableRoles.filter(x => roles.includes(x.value))
      this.roles = this.roles.map(x=>({...x, action: 'Viewer'}))
      this.tabs = this.tabs.filter(x => x.roleValue ? this.roles.map(x=>x.value).includes(x.roleValue) : true)
      let findShare = this.profile.consigneeProfileShares.find(x => x.sharedPartyId == this.$store.state.currentOrg.id)
      findShare = findShare ? findShare.share: false;
      this.profile.editable = (this.profile.ownerId == this.$store.state.currentOrg.id) || findShare
      this.loading = false;
    },
  },
};
</script>
<style scoped>
* {
  --gutters: 0.1rem;
  --toolBarHeight: 36px;
  --tabHeight: 30px;
  --darkLayer: var(--v-greyDarker-base) !important;
}
.contents {
  display: flex;
  width: 100%;
  height: calc(95vh - var(--toolBarHeight));
}
.mainContentWrap {
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 50%;
  max-width: 100%;
  position: relative;
}
.mainContentTabs {
  height: var(--tabHeight) !important;
}
.mainContent {
  max-height: calc(100vh - var(--toolBarHeight) - var(--tabHeight));
  height: 100%;
  width: 100%;
  background: transparent !important;
}
.tabby ::v-deep.theme--dark.v-tabs .v-tabs-bar,
.tabby ::v-deep .v-tabs-bar__content,
.tabby ::v-deep .v-tab {
  background: transparent !important;
  border-radius: 0px;
}
</style>